<template>
  <div>
    <img style="width: 100%;" alt="" src="@/assets/images/patientInfo.png" />
    <div class="ml-10 mr-10">
      <h2>{{ $t("socialhistory") }}</h2>

      <!-- <div class="ml-10 mr-10 mt-10">
        <point v-for="item in points" :data="item" :key="item.title" />
      </div> -->
      <div class="w-col w-full">
        <div class="ml-20 mr-10 ">
          <div div class="vx-row w-full justify-left m-6  ">
            <b class="  text-left m-2">
              <p>{{ $t("MaritalStatus") }}</p></b
            >

            <vs-radio class="m-3 " v-model="radios1" vs-value="luis">{{
              $t("Single")
            }}</vs-radio>
            <vs-radio class="m-3" v-model="radios1" vs-value="Divorced">{{
              $t("Divorced")
            }}</vs-radio>
            <vs-radio class="m-3" v-model="radios1" vs-value="Widowed">{{
              $t("Widowed")
            }}</vs-radio>
            <vs-radio class="m-3" v-model="radios1" vs-value="carols">{{
              $t("Married")
            }}</vs-radio>
          </div>
        </div>
        <div class="ml-20 mr-10 ">
          <div class="vx-row xl:w-full sm:w-1/2 justify-left m-6  ">
            <b class="vx-row xl:w-1/1 sm:w-1/4 text-left m-2">
              <p>{{ $t("MaritalStatus") }}</p></b
            >
            <vs-radio class="m-1" v-model="radios3" vs-value="3">{{
              $t("yes")
            }}</vs-radio>
            <vs-radio class="m-1 xl:w-1/1  " v-model="radios3" vs-value="2">{{
              $t("no")
            }}</vs-radio>

            <p class="m-1">{{ $t("Ifyeshowmany") }}:</p>

            <vs-input class="xl:w-1/1 sm:w-full" placeholder="Placeholder" />
            <p class="m-1">{{ $t("Age") }}:</p>

            <vs-input class="xl:w-1/1 sm:w-full" placeholder="Placeholder" />
          </div>
        </div>
        <div class="ml-20 mr-10 ">
          <div div class="vx-row w-full justify-left m-6  ">
            <b class=" xl:w-1/1 sm:w-1/4 text-left m-2">
              <p class="">{{ $t("Internationalinsurance") }}</p></b
            >

            <vs-radio class="m-1 " v-model="radios2" vs-value="9">{{
              $t("yes")
            }}</vs-radio>
            <vs-radio class="m-1 w-1/1 " v-model="radios2" vs-value="8">{{
              $t("no")
            }}</vs-radio>

            <p class="xl:w-4/11 sm:w-1/4">{{ $t("Insurancecompanyname") }}:</p>

            <vs-input class="xl:w-1/1 sm:w-full" placeholder="Placeholder" />
          </div>
        </div>
        <div class="ml-20 mr-10 ">
          <div div class="vx-row w-full justify-left m-6  ">
            <b class="xl:w-1/1 sm:w-full text-left m-2">
              <p>{{ $t("Areyouasmoker") }}</p></b
            >
            <vs-radio
              class="m-1 xl:w-1/1 sm:w-1/4 "
              v-model="radios4"
              vs-value="4"
              >{{ $t("yes") }}</vs-radio
            >
            <vs-radio
              class="m-1 xl:w-1/1 sm:w-1/4"
              v-model="radios4"
              vs-value="5"
              >{{ $t("no") }}</vs-radio
            >
            <div class="vx-row w-1/2 ml-4">
              <p>{{ $t("Level") }}:</p>
              <div class="vx-col ">
                <vs-radio class="m-1 " v-model="radios6" vs-value="6">{{
                  $t("Heavy")
                }}</vs-radio>
                <vs-radio class="m-1 " v-model="radios6" vs-value="7">{{
                  $t("Moderate")
                }}</vs-radio>
                <vs-radio class="m-1 " v-model="radios6" vs-value="8">{{
                  $t("Occasionaly")
                }}</vs-radio>
              </div>
            </div>
          </div>
        </div>
        <h4>
          <P
            >Clarification of each criteria: 6 cigarettes a day considered as
            moderate</P
          >
        </h4>
        <div class="ml-20 mr-10 ">
          <div class="vx-row w-full justify-left m-6  ">
            <b class=" xl:w-1/1 sm:w-1/4 text-left m-2">
              <p>{{ $t("Doyoudrinkalcohol") }}</p></b
            >
            <vs-radio class="m-1 " v-model="radios7" vs-value="9">{{
              $t("Yescurrently")
            }}</vs-radio>
            <vs-radio
              class="m-1 xl:w-1/4 sm:w-1/4"
              v-model="radios7"
              vs-value="10"
              >{{ $t("Yesbutoccasionallyrarely") }}</vs-radio
            >
            <vs-radio class="m-1 " v-model="radios7" vs-value="11">{{
              $t("NoIquit")
            }}</vs-radio>
            <vs-radio class="m-1 " v-model="radios7" vs-value="12">{{
              $t("neverdid")
            }}</vs-radio>
          </div>
        </div>
        <div class="vx-row xl:w-full sm:w-full justify-center">
          <p class="m-2">{{ $t("Howmanydrinksperday") }}:</p>
          <vs-input
            class="xl:w-1/1 sm:w-full"
            placeholder="Placeholder"
          ></vs-input>
        </div>
        <div class="ml-20 mr-10 ">
          <div class="vx-row w-full justify-left m-6  ">
            <b class=" xl:w-4/11 text-left m-2">
              <p>{{ $t("Haveyougonethroughanysurgerybefore") }}</p></b
            >
            <vs-radio class="m-1 " v-model="radios8" vs-value="12">{{
              $t("yes")
            }}</vs-radio>

            <vs-radio
              class="vx-row xl:w-1/1 sm:w-1/4 m-1 "
              v-model="radios8"
              vs-value="13"
              >{{ $t("NoIquit") }}</vs-radio
            >
            <p class="m-2">{{ $t("Ifyesdescripe") }}:</p>
            <vs-input class="xl:w-1/1 sm:w-full" placeholder="placeholder">
            </vs-input>
          </div>
        </div>
        <div class="ml-20 mr-10">
          <div class="vx-row xl:w-full sm:w-full justify-left m-6">
            <b class=" xl:w-4/11 sm:w-1/4 text-left m-2">
              <p>{{ $t("Areyoudiagnosedwithloworhighpressure") }}</p>
            </b>
            <vs-radio
              class=" m-1 xl:w-1/1 sm:w-1/1"
              v-model="radios9"
              vs-value="14"
              >{{ $t("Highpressure") }}</vs-radio
            >
            <vs-radio
              class="xl:w-1/1 sm:w-1/4 m-1"
              v-model="radios9"
              vs-value="15"
              >{{ $t("Lowpressure") }}</vs-radio
            >
            <vs-radio
              class="xl:w-1/1 sm:w-full m-1"
              v-model="radios9"
              vs-value="16"
              >{{ $t("no") }}</vs-radio
            >
          </div>
        </div>
        <div class="ml-20 mr-10">
          <div class="vx-row w-full justify-left m-6">
            <b class=" xl:w-4/11 sm:w-1/4 text-left m-2">
              <p>{{ $t("Areyouasthmatic") }}</p>
            </b>
            <vs-radio class=" m-1" v-model="radios10" vs-value="17">{{
              $t("yes")
            }}</vs-radio>
            <vs-radio
              class="xl:w-1/1 sm:w-1/4 m-1"
              v-model="radios10"
              vs-value="18"
              >{{ $t("no") }}</vs-radio
            >
            <p class="m-2">{{ $t("Ifyesdescripe") }}:</p>
            <vs-input
              class="xl:w-1/1 sm:w-full "
              placeholder="placeholder"
            ></vs-input>
          </div>
        </div>
        <div class="ml-20 mr-10">
          <div class="vx-row xl:w-full  justify-left m-6">
            <b class=" text-left m-2">
              <p>
                {{
                  $t("HaveyousufferedfromanydiseasesuchasMalariahepatitisetc")
                }}
              </p>
            </b>
            <vs-radio class=" m-1" v-model="radios11" vs-value="19">{{
              $t("yes")
            }}</vs-radio>
            <vs-radio class=" w-1/1 m-1" v-model="radios11" vs-value="20">{{
              $t("no")
            }}</vs-radio>
          </div>
          <div class="vx-row xl:w-full sm:w-full justify-center">
            <p class="m-2">{{ $t("Howmanydrinksperday") }}:</p>
            <vs-input
              class="xl:w-1/1 sm:w-1/2"
              placeholder="Placeholder"
            ></vs-input>
          </div>
        </div>
        <div class="ml-20 mr-10 ">
          <div class="vx-row xl:w-full sm:w-full justify-left m-6  ">
            <b class=" xl:w-4/11 sm:w-1/2 text-left m-2">
              <p>{{ $t("Anycancerdiagnosishistoryinyourfamily") }}</p></b
            >
            <vs-radio class="m-1 " v-model="radios12" vs-value="14">{{
              $t("yes")
            }}</vs-radio>

            <vs-radio
              class="vx-row xl:w-1/1 m-1 "
              v-model="radios12"
              vs-value="15"
              >{{ $t("NoIquit") }}</vs-radio
            >
            <p class=" m-2">{{ $t("Ifyesdescripe") }}:</p>
            <vs-input class="xl:w-1/1 sm:w-1/2" placeholder="placeholder">
            </vs-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VxInputGroup from "../../components/vx-input-group/VxInputGroup.vue";
// import point from "../../components/point.vue";
export default {
  components: {
    VxInputGroup
    // point
  },
  data() {
    return {
      options: [],
      radios1: "",
      radios2: "",
      radios3: "",
      radios4: "",
      radios6: "",
      radios7: "",
      radios8: "",
      radios9: "",
      radios10: "",
      radios11: "",
      radios12: ""
    };
  },
  methods: {}
};
</script>
<style>

.textfont {
  font-family: "futuraMedium";
}
</style>
